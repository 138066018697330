<template>
  <div class="min-h-screen bg-gray-200 dark:bg-gray-800 shadow-sm">
    <main class="py-5">
     <div
        class=" "
      >


       
      </div>
       <div class="mx-auto bg-white dark:bg-gray-900 p-10 rounded text-gray-900 dark:text-white text-xl">

         <div>
            <h1 class="text-4xl text-gray-900 dark:text-white  font-bold mb-5">Promote your token</h1>
          </div>

<h2 class="text-2xl">Want to promote your project?</h2>

  <p class="my-5">We offer Promoted Coin spots and Banner Ad spots.</p> 
  <p class="my-5">For prices and information, please email us at: info@coincoin.com</p>
       </div>
    </main>
  </div>

  
</template>

<script>

export default {
  name: "Promote",
};
</script>
